// Korean
export default{
    form: {
        startSurveyButton: "시작하기",
        nextButton: "다음",
        submitAnswerButton: "답변 제출",
        continueButton: "계속",
        finishButton: "완료",
        headerPercentageCompletion: '완료됨',
        headerQuestionsCompleted: '질문',
        headerDefaultErrorMessage: '문제가 발생했습니다.',
        brandingPoweredBy: '제공자:',
        brandingPromoWhySurvey: '왜 설문조사 하나요? <b>{product}</b>로 더 쉽습니다!',
        brandingPromoGetStarted: '지금 시작하세요!',
        submissionPending: "음성 폼을 제출 중입니다. 브라우저 창을 닫지 마세요.",
        submissionCanCloseWindow: "음성 폼이 성공적으로 제출되었습니다. 이제 창을 닫으셔도 됩니다 👍",
        mediaAttachmentOverlayCloseButton: "닫기",
        expiredFormHeader: "방문해 주셔서 감사합니다!",
        expiredFormMessage: "이 설문조사는 만료된 것 같습니다. 설문 작성자에게 문의하세요.",
        notPublishedFormHeader: "음성 폼이 게시되지 않았습니다!",
        notPublishedFormMessage: "이 설문조사가 아직 게시되지 않았습니다. 설문 작성자에게 문의하세요.",
        notPublishedFormRetryButton: "다시 시도",
        errorFormHeader: "오류!",
        errorFormMessage: "문제가 발생한 것 같습니다. 나중에 다시 시도해주세요.",
        errorFormRetryButton: "다시 시도",
        emptyFormHeader: "방문해 주셔서 감사합니다!",
        emptyFormMessage: "이 폼에는 현재 질문이나 입력할 필드가 없습니다. 불편을 드려 죄송합니다. 문제를 해결하려면 폼 작성자에게 연락해 주세요. 감사합니다!",
        
        restoreFormHeader: "작성 중인 폼이 있는 것 같습니다!",
        restoreFormMessage: "이전에 중단한 부분부터 계속하시겠습니까?",
        restoreFormButton: "폼 계속하기",
        restoreFormButtonNew: "처음부터 시작",
        
        audioPermissionHeader: "음성으로 답변하시겠습니까?",
        audioPermissionMessage: "음성 답변이 더 쉽고 빠릅니다.",
        audioPermissionAcceptButton: "예, 그렇게 하고 싶습니다",
        audioPermissionDenyButton: "아니요, 괜찮습니다",
        audioPermissionErrorHeader: "마이크를 사용할 수 없습니다",
        audioPermissionErrorMessage: "브라우저 권한을 확인해 마이크 접근을 허용해주세요.",
        audioPermissionErrorButton: "확인",
        audioRecorderFailedUpload: "녹음 업로드 실패",
        silenceDetectorError: "소리가 들리지 않습니다. 마이크 설정을 확인해주세요. 팁: 외부 마이크일 수 있습니다.",
        audioRecorderButtonRecord: "녹음 시작",
        audioRecorderButtonRecordMore: "추가 녹음",
        voiceResponseSelectionMessage: "답변 방식을 선택하세요...",
        voiceResponseSelectionOrDivider: "또는",
        voiceResponseTextInputPlaceholder: "답변 입력",
        voiceResponseTextInputCharacterCounterMinMessage: "최소 {min}자 이상 입력해주세요",
        voiceResponseTextInputCharacterCounterCurrentNumberMessage: "{count}자 (최소 {min}자)",
        voiceResponseTextInputCharacterValidationMessage: "{count}자 (최소 {min}자)",

        // checkbox
        checkboxValidationTooFew: "최소 {min}개의 옵션을 선택해야 합니다",
        checkboxValidationTooMany: "최대 {max}개의 옵션만 선택할 수 있습니다",
        checkboxNoneOfTheAboveOption: "해당 없음",
        checkboxInvalidAnswer: "잘못된 답변입니다.",

        // datepicker
        datePickerPlaceholder: "날짜 선택",

        // dropdown
        // email
        emailLabel: "이메일",
        emailInvalid: "유효하지 않은 이메일입니다.",
        // file-upload
        fileUploadPluginNameCamera: "카메라",
        fileUploadPluginNameCameraVideo: "비디오 녹화",
        fileUploadPluginScreenCast: "화면 녹화",
        fileUploadVideoImportFilesDefault: '비디오 녹화 또는 업로드:',
        fileUploadVideoImportFilesLocalFilesDisabled: '비디오 녹화 옵션 선택:',
        fileUploadVideoImportFilesNoCamera: '화면 녹화 버튼을 누르세요',
        fileUploadVideoImportFilesNoScreenCast: '비디오 녹화 버튼을 누르세요',
        fileUploadVideoImportFilesNoCameraAndScreenCast: '비디오 녹화 옵션이 없습니다',

        // matrix
        matrixValidationMessage: "유효한 답변입니다.",
        matrixRow: "행",
        matrixColumn: "열",
        matrixRowRequired: "행 {rowTitle}은(는) 필수입니다.",
        matrixRadioGroup: "라디오 그룹",
        matrixCheckboxGroup: "체크박스 그룹",
        matrixGroupRequired: "행 {rowTitle}에 대한 {type} {groupTitle}이(가) 필요합니다.",
        matrixColumnRequired: "행 {rowTitle}에 대한 열 {columnTitle}이(가) 필요합니다.",
        matrixColumnInvalid: "행 {rowTitle}에 대한 열 {columnTitle}이(가) 유효하지 않습니다.",
        matrixRequired: "필수입니다.",
        matrixNumericMustBeNumber: "숫자여야 합니다.",
        matrixNumericMustBeGreaterThenMin: "{min}보다 커야 합니다.",
        matrixNumericMustBeLessThenMax: "{max}보다 작아야 합니다.",
        matrixNumericMustBeInteger: "정수여야 합니다.",
        matrixNumericInvalidNumber: "유효하지 않은 숫자입니다.",

        // name
        nameLabel: "이름",
        nameInvalid: "이름과 성을 입력해주세요.",

        // nps
        npsNotLikely: "전혀 가능하지 않음",
        npsExtremelyLikely: "매우 가능함",

        // numeric input
        numericInputRequired: "필수입니다.",
        numericInputMustBeNumber: "숫자여야 합니다.",
        numericInputMustBeGreaterThenMin: "{min}보다 커야 합니다.",
        numericInputMustBeLessThenMax: "{max}보다 작아야 합니다.",
        numericInputMustBeInteger: "정수여야 합니다.",
        numericInputInvalidNumber: "유효하지 않은 숫자입니다.",
        numericInputPlaceholder: "숫자 입력",

        // phone
        phoneInvalid: "유효하지 않은 전화번호입니다.",
        phoneCountrySelectorLabel: '국가 코드',
        phoneCountrySelectorError: '국가 선택',
        phoneNumberLabel: '전화번호',
        phoneExample: '예시:',

        // boolean
        booleanYesLabel: "예",
        booleanNoLabel: "아니요",

        //questionStep
        questionStepAudioQuestionLabel: "음성 질문",

        // errors
        invalidPhoneNumber: "{phone}은(는) 유효하지 않은 전화번호입니다.",
        invalidEmail: "{email}은(는) 유효하지 않은 이메일 주소입니다.",
        questionIsRequired: "이 질문은 필수입니다.",
        answerIsNotValid: "이 답변은 유효하지 않습니다.",
        unfinishedProbingDialogError: "대화를 완료해주세요.",
        cannotResumePartialResponse: "부분 응답을 재개할 수 없습니다.",
        failedToSubmitForm: "폼 제출 실패. 인터넷 연결을 확인하고 다시 시도하세요."
    }
}
